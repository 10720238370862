import { ref, computed, isRef } from "vue"
import { debounce } from "lodash-es"

const DEBOUNCE_MS = 300

export function useAsyncValidation(initialValue, validator, isManual) {
  const isValid = ref(true)
  const isTyping = ref(false)
  const error = ref("")
  const isLoading = ref(false)
  const _subscrubers = ref([])
  const subscribeToValidation = (fn) => {
    _subscrubers.value.push(fn)
  }

  const doValidation = debounce(async (value) => {
    isValid.value = true
    isLoading.value = true
    const _v = isRef(validator) ? validator.value : validator
    await _v(value)
      .then((_err) => {
        isValid.value = !_err
        error.value = _err
      })
      .finally(() => {
        isTyping.value = false
        isLoading.value = false
        _subscrubers.value.forEach((fn) => fn(error.value))
      })
  }, DEBOUNCE_MS)

  const _model = ref(initialValue)
  const model = computed({
    get() {
      return _model.value
    },

    async set(value) {
      if (value === _model.value) {
        return
      }

      if (!isTyping.value && !isManual) {
        isTyping.value = true
      }

      _model.value = value
      if (!isManual) {
        await doValidation(value)
      }
    },
  })

  return {
    isValid,
    isTyping,
    model,
    error,
    isLoading,
    doValidation,
    subscribeToValidation,
  }
}
